export function setBlinderBox(openText,closeText){
  
  let targets = document.getElementsByClassName("js_bliderBox_trigger");
  
  for(let i = 0; i < targets.length; i++){
    
    targets[i].addEventListener("click",(e) => {
      
      let self = targets[i].closest('.js_bliderBox');
      let selfOffsetTop = self.offsetTop;
      
      if(self.classList.contains('js_isActive')){
        self.classList.remove('js_isActive');
        targets[i].textContent = openText;
        
        window.scroll({
          top: selfOffsetTop,
          behavior: 'smooth'
        });
        
      }else{
        self.classList.add('js_isActive');
        targets[i].textContent = closeText;
      }
      
    });
  }
}